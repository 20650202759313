import App from './views/app.js';
import './views/component/app-bar.js';

const app = new App({
	content: document.querySelector('#main-content'),
});
window.addEventListener('hashchange', () => {
	app.renderPage();
});

window.addEventListener('DOMContentLoaded', () => {
	app.renderPage();

	try {
		Dropzone.autoDiscover = false;
	} catch (error) {
		console.log(error)
	}
	setTimeout( async () => {
		await app._setNotification();
	}, 30000);

	setInterval( async () => {
		await app._setNotification();
	}, 2500000);

	setTimeout( async () => {
		await app.refreshToken();
	}, 3000);

	setInterval( async () => {
		await app.refreshToken();
	}, 20000);
	
	$.fn.dataTable.ext.errMode = function ( settings, helpPage, message ) { 
		console.log(message);
	};

	$(document).on('show.bs.modal', '.modal', function() {
		const zIndex = 1040 + 10 * $('.modal:visible').length;
		$(this).css('z-index', zIndex);
		setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
	
	  });
	  $(window).on('popstate', function() { 
		$(".modal").modal('hide');
	  });


	setInterval( async () => {
		var p = new Ping();

		// Using callback
		p.ping("https://github.com", function(err, delta) {
		  // Also display error if err is returned.
		  if (err) {
			$('#ping-real-time').css({
				'color': 'rgba(231, 74, 59, 0.6)',
				'bottom': '0'
			});
			$('#containerPing').css({
				'color': 'rgba(231, 74, 59, 0.6)',
				'bottom': '0'
			})

			$('#ping-real-time').html('--' + 'ms');
			return
		  }else{
			if (delta > 500) {
			
				$('#ping-real-time').css({
					'color': 'rgba(231, 74, 59, 0.6)',
					'bottom': '0'
				});
				$('#containerPing').css({
					'color': 'rgba(231, 74, 59, 0.6)',
					'bottom': '0'
				})
			} else {
				$('#ping-real-time').css({
					'color': 'rgba(28, 200, 138, 0.6)',
					'bottom': '0'
				});
				$('#containerPing').css({
					'color': 'rgba(28, 200, 138, 0.6)',
					'bottom': '0'
				})
			}
			$('#ping-real-time').html(delta + 'ms');
	
		  }
		});
	}, 1000);

	
	//mixpanel.init('cdf91b6a852e65652cc8519d4cb1837b', {debug: true, track_pageview: true, persistence: 'localStorage'});
	mixpanel.init('e5df693a3721f526855987a3704a027d');

	mixpanel.track("First Open Web");
	

	  
});